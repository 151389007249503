// src/components/VisionStatement.jsx
import React from 'react';
import './VisionStatement.css';

const VisionStatement = () => {
  return (
    <div className="common-container vision-section">
      <p className="vision-text">
        <strong>Our vision is to transform society through the unifying language of football</strong>
      </p>
    </div>
  );
};

export default VisionStatement;
