import React, { useEffect, useState } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import './EngageSection.css';

const EngageSection = () => {
    const [num1, setNum1] = useState(0);
    const [num2, setNum2] = useState(0);
    const [captchaAnswer, setCaptchaAnswer] = useState(0);
    const [countries, setCountries] = useState([]);
    const [formData, setFormData] = useState({
        your_name: '',
        email: '',
        birthday: '',
        ageRange: '',
        gender: '',
        street_address: '',
        street_address2: '',
        city: '',
        zip_code: '',
        country: '',
        captcha: ''
    });
    const [captchaError, setCaptchaError] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    useEffect(() => {
        // Generate random numbers for captcha
        const randomNum1 = Math.floor(Math.random() * 10);
        const randomNum2 = Math.floor(Math.random() * 10);
        setNum1(randomNum1);
        setNum2(randomNum2);
        setCaptchaAnswer(randomNum1 + randomNum2);

        // Fetch and populate countries
        fetch('https://restcountries.com/v2/all')
            .then(response => response.json())
            .then(data => setCountries(data))
            .catch(error => console.error('Error fetching countries:', error));

        // Initialize date picker for birthday
        flatpickr("#birthday", {
            dateFormat: "d-m",
            minDate: "01-01",
            maxDate: "31-12",
            altInput: true,
            altFormat: "F j",
        });
    }, []);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (parseInt(formData.captcha) !== captchaAnswer) {
            setCaptchaError(true);
            return;
        }

        setCaptchaError(false);

        // Simulate form submission
        console.log('Form submitted', formData);

        setFormSubmitted(true);
        setFormData({
            your_name: '',
            email: '',
            birthday: '',
            ageRange: '',
            gender: '',
            street_address: '',
            street_address2: '',
            city: '',
            zip_code: '',
            country: '',
            captcha: ''
        });
    };

    return (
        <section className="contact-form-section">
            <div className="container">
                <div className="mb-4">
                    <h2><strong>Building The Vyaelea Global Community</strong></h2>
                    <p><strong>Let us build an all-encompassing integral identity as the Vyaelea Global Community (VGC).
                        As part of the VGC, you will receive news, updates, and articles.</strong></p>
                </div>
                <form className="contact-form" onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="your_name" className="expandable-label">Your Name:</label>
                        <input type="text" className="form-control expandable-input" id="your_name" name="your_name" value={formData.your_name} onChange={handleChange} required style={{ fontSize: '20px', backgroundColor: '#0C0A00', color: 'gold', borderColor: 'gold' }}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className="expandable-label">Email:</label>
                        <input type="email" className="form-control expandable-input" id="email" name="email" value={formData.email} onChange={handleChange} required style={{ fontSize: '20px', backgroundColor: '#0C0A00', color: 'gold', borderColor: 'gold' }} />
                    </div>
                    <div className="mb-3" style={{ fontSize: '20px', backgroundColor: '#0C0A00', color: 'gold', borderColor: 'gold' }}>
                        <label htmlFor="birthday" className="expandable-label">Birthday (Click to Select Date and Month):</label>
                        <input
                            type="text"
                            id="birthday"
                            name="birthday"
                            value={formData.birthday}
                            onChange={handleChange}
                            required
                            className="form-control expandable-input"
                            style={{
                                fontSize: '20px',
                                backgroundColor: '#0C0A00 !important',
                                color: 'gold',
                                borderColor: 'gold !important', // Ensuring the border color is gold
                                borderWidth: '2px', // Making sure border width is sufficient to be visible
                            }}
                        />
                        <div className="invalid-feedback form-validation-error" style={{ fontSize: '20px', backgroundColor: '#0C0A00', color: 'gold', borderColor: 'gold' }}>
                            Please provide a valid birthday.
                        </div>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="ageRange" className="expandable-label">Age Range:</label>
                        <select id="ageRange" name="ageRange" value={formData.ageRange} onChange={handleChange} required className="form-select expandable-input" style={{ fontSize: '20px', backgroundColor: '#0C0A00', color: 'gold', borderColor: 'gold' }}>
                            <option value="" disabled selected>- Select -</option>
                            <option value="<18">&lt;18</option>
                            <option value="19-33">19-33</option>
                            <option value="34-49">34-49</option>
                            <option value="50-65">50-65</option>
                            <option value="65+">65+</option>
                        </select>
                        <div className="invalid-feedback form-validation-error"style={{ fontSize: '20px', backgroundColor: '#0C0A00', color: 'gold', borderColor: 'gold' }}>
                            Please select an age range.
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="gender" className="expandable-label">Gender:</label>
                        <select id="gender" name="gender" value={formData.gender} onChange={handleChange} required className="form-select expandable-input"style={{ fontSize: '20px', backgroundColor: '#0C0A00', color: 'gold', borderColor: 'gold' }}>
                            <option value="" disabled selected>- Select -</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Prefer not to disclose</option>
                        </select>
                        <div className="invalid-feedback form-validation-error"style={{ fontSize: '20px', backgroundColor: '#0C0A00', color: 'gold', borderColor: 'gold' }}>
                            Please select a gender.
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="street_address" className="expandable-label">Street Address 1:</label>
                        <input type="text" className="form-control expandable-input" id="street_address" name="street_address" value={formData.street_address} onChange={handleChange}style={{ fontSize: '20px', backgroundColor: '#0C0A00', color: 'gold', borderColor: 'gold' }} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="street_address2" className="expandable-label">Street Address 2:</label>
                        <input type="text" className="form-control expandable-input" id="street_address2" name="street_address2" value={formData.street_address2} onChange={handleChange} style={{ fontSize: '20px', backgroundColor: '#0C0A00', color: 'gold', borderColor: 'gold' }}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="city" className="expandable-label">City:</label>
                        <input type="text" className="form-control expandable-input" id="city" name="city" value={formData.city} onChange={handleChange}style={{ fontSize: '20px', backgroundColor: '#0C0A00', color: 'gold', borderColor: 'gold' }} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="zip_code" className="expandable-label">Zip Code:</label>
                        <input type="text" className="form-control expandable-input" id="zip_code" name="zip_code" value={formData.zip_code} onChange={handleChange}style={{ fontSize: '20px', backgroundColor: '#0C0A00', color: 'gold', borderColor: 'gold' }} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="country" className="expandable-label">Country:</label>
                        <select id="country" name="country" value={formData.country} onChange={handleChange} required className="form-select expandable-input"style={{ fontSize: '20px', backgroundColor: '#0C0A00', color: 'gold', borderColor: 'gold' }}>
                            <option value="" disabled selected>- Select -</option>
                            {countries.map((country) => (
                                <option key={country.alpha2Code} value={country.name}>{country.name}</option>
                            ))}
                        </select>
                        <div className="invalid-feedback form-validation-error"style={{ fontSize: '20px', backgroundColor: '#0C0A00', color: 'gold', borderColor: 'gold' }}>
                            Please select a country.
                        </div>
                    </div>
                    <div className="mb-3" style={{ width: '60%' }}>
                        <label htmlFor="captcha" style={{ color: 'gold' }}>Captcha:</label>
                        <div className="captcha-input">
                            <div className="captcha-description">
                                This question enables the prevention of automated robotic submissions.
                            </div>
                            <div className="captcha-question">
                                <span id="num1">{num1}</span> + <span id="num2">{num2}</span> = ?
                            </div>
                            <input type="text" className="form-control" id="captcha" name="captcha" value={formData.captcha} onChange={handleChange} required style={{ fontSize: '20px', backgroundColor: '#0C0A00', color: 'gold', borderColor: 'gold'  }} />
                            <p className="captcha-guide" style={{ color: 'gold' }}>Enter the sum of the two numbers above.</p>
                            {captchaError && (
                                <p className="captcha-error-message" style={{ color: 'red', fontSize: '14px' }}>Incorrect answer. Please try again.</p>
                            )}
                        </div>
                    </div>

                   <div className="mb-3" style={{ textAlign: 'left', color: 'gold', backgroundColor: '#0C0A00', padding: '30px 0' }}>
                              <button type="submit" className="btn custom-submit-button" style={{ width: 'auto', borderColor: 'gold', backgroundColor: '#0C0A00',color:'gold' }}>Submit</button>
                            </div>
                </form>
                {formSubmitted && (
                    <div className="mt-3 alert alert-success">
                        Form submitted successfully!
                    </div>
                )}
            </div>
        </section>
    );
};

export default EngageSection;
