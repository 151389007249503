// src/components/MissionStatement.jsx
import React from 'react';
import './MissionStatement.css';

const MissionStatement = () => {
  return (
    <div className="common-container mission-section">
      <p className="mission-text">
        <strong>Our mission is to innovatively address the equality gap, foster peace, and promote good health.</strong>
      </p>
    </div>
  );
};

export default MissionStatement;
